// PUBLIC ROUTES
import { isNotNull } from "./Utils";

export const PUBLIC_LOGIN_LINK = "/login";
export const PUBLIC_SIGNUP_LINK = "/signup";
export const PUBLIC_FIRST_LOGIN_LINK = "/firstLogin";
export const PUBLIC_RESET_LINK = "/ResetPassword";
export const PUBLIC_SUCCESS_LINK = "/success";
export const PUBLIC_HOME_LINK = "/";

// DOCTOR ROUTES
export const DOCTOR_DASHBOARD_HOME_LINK = "/doctor";
export const DOCTOR_DASHBOARD_ASSISTANCE_LINK = "/doctor/assistances";
export const DOCTOR_DASHBOARD_CONSULTATIONS_LINK = "/doctor/consultations";
export const DOCTOR_DASHBOARD_CALENDAR_LINK = "/doctor/calendar";
export const DOCTOR_DASHBOARD_PATIENTS_LINK = "/doctor/patients";
export const DOCTOR_DASHBOARD_SHARED_PATIENTS_LINK = "/doctor/shared patients";
export const DOCTOR_DASHBOARD_PREGNANCIES_LINK = "/doctor/pregnancies";
export const DOCTOR_ADD_PATIENT_LINK = "/doctor/patients/add";
export const DOCTOR_DASHBOARD_PAHRMACIES_LINK = "/doctor/pharmacies";
export const DOCTOR_DASHBOARD_LABOS_LINK = "/doctor/labos";
export const DOCTOR_DASHBOARD_SERVICE_LINK = "/doctor/service";
export const DOCTOR_DASHBOARD_ANALYTICS_LINK = "/doctor/analytics";
export const DOCTOR_DASHBOARD_SETTINGS_LINK = "/doctor/settings";
export const DOCTOR_DASHBOARD_HELP_LINK = "/doctor/help";
export const DOCTOR_DASHBOARD_PRIVACY_LINK = "/doctor/privacy";
export const DOCTOR_DASHBOARD_PROFILE_LINK = "/doctor/profile";
export const DOCTOR_DASHBOARD_CHAT_LINK = "/doctor/chat";

// PHARMACY ROUTES
export const PHARMACY_DASHBOARD_HOME_LINK = "/pharmacy";
export const PHARMACY_DASHBOARD_MEDICAMENTS_LINK = "/pharmacy/medicaments";
export const PHARMACY_DASHBOARD_ASSISTANCES_LINK = "/pharmacy/assistances";
export const PHARMACY_DASHBOARD_SUPPLIERS_LINK = "/pharmacy/suppliers";
export const PHARMACY_DASHBOARD_STOCK_LINK = "/pharmacy/stock";
export const PHARMACY_DASHBOARD_STOCK_HISTORY_LINK = "/pharmacy/stock-history";
export const PHARMACY_DASHBOARD_COMMANDS_LINK = "/pharmacy/commands";
export const PHARMACY_DASHBOARD_PROFILE_LINK = "/pharmacy/profile";
export const PHARMACY_DASHBOARD_SETTINGS_LINK = "/pharmacy/settings";
export const PHARMACY_DASHBOARD_HELP_LINK = "/pharmacy/help";
export const PHARMACY_DASHBOARD_PRIVACY_LINK = "/pharmacy/privacy";
export const PHARMACY_DASHBOARD_CHAT_LINK = "/pharmacy/chat";

// LABO ROUTES
export const LABO_DASHBOARD_HOME_LINK = "/labo";
export const LABO_DASHBOARD_ASSISTANCES_LINK = "/labo/assistances";
export const LABO_DASHBOARD_ANALYSES_LINK = "/labo/bilans";
export const LABO_DASHBOARD_MESURES_LINK = "/labo/mesures";
export const LABO_DASHBOARD_PATIENTS_LINK = "/labo/patients";
export const LABO_DASHBOARD_ADD_PATIENT_LINK = "/labo/patients/add";
export const LABO_DASHBOARD_DEMANDES_LINK = "/labo/demandes";
export const LABO_DASHBOARD_PROFILE_LINK = "/labo/profile";
export const LABO_DASHBOARD_SETTINGS_LINK = "/labo/settings";
export const LABO_DASHBOARD_HELP_LINK = "/labo/help";
export const LABO_DASHBOARD_PRIVACY_LINK = "/labo/privacy";

export const LABO_DASHBOARD_CHAT_LINK = "/labo/chat";

// HOSPITAL ROUTES

export const HOSPITAL_DASHBOARD_HOME_LINK = "/hospital";
export const HOSPITAL_DASHBOARD_SETTINGS_LINK = "/hospital/settings";
export const HOSPITAL_DASHBOARD_HELP_LINK = "/hospital/help";
export const HOSPITAL_DASHBOARD_SERVICE_LINK = "/hospital/service";
export const HOSPITAL_DASHBOARD_DEPARTMENT_LINK = "/hospital/department";
export const HOSPITAL_DASHBOARD_FLOORS_LINK = "/hospital/floor";
export const HOSPITAL_DASHBOARD_ROOMS_LINK = "/hospital/room";
export const HOSPITAL_DASHBOARD_BEDS_LINK = "/hospital/bed";
export const HOSPITAL_DASHBOARD_PRIVACY_LINK = "/hospital/privacy";
export const HOSPITAL_DASHBOARD_PROFILE_LINK = "/hospital/profile";
export const HOSPITAL_DASHBOARD_EMPLOYEE_LINK = "/hospital/employee";
export const HOSPITAL_DASHBOARD_APPOINTMENT_LINK = "/hospital/appointment";
export const HOSPITAL_DASHBOARD_RESERVATION_LINK = "/hospital/reservation";
export const HOSPITAL_DASHBOARD_CHAT_LINK = "/hospital/chat";
export const HOSPITAL_EMPLOYEE_CREATE_EMPLOYEE = "/hospital/createEmployee";
export const HOSPITAL_EMPLOYEE_CREATE_DOCTOR = "/hospital/createDoctor";
export const HOSPITAL_EMPLOYEE_ADD_EMPLOYEE = "/hospital/AddDoctor";
export const HOSPITAL_DASHBOARD_CALENDAR_LINK = "/hospital/calendar";
export const HOSPITAL_DASHBOARD_ROOM_CALENDAR_LINK = "/hospital/RoomCalendar";
export const HOSPITAL_DASHBOARD_BIEN_LINK = "/hospital/bien";
export const HOSPITAL_DASHBOARD_CONVENTION_LINK = "/hospital/convention";
export const HOSPITAL_DASHBOARD_ADMISSION_LINK = "/hospital/admission";
export const HOSPITAL_DASHBOARD_RADIOLOGY_LINK = "/hospital/radiology";
export const HOSPITAL_DASHBOARD_BLOOD_LINK = "/hospital/blood";

// RULES
export const DOCTOR_ROLE = 1;
export const ASSISTANT_ROLE = 2;
export const PHARMACY_ROLE = 3;
export const LABO_ROLE = 4;
export const HOSPITAL_ROLE = 5;

export const DOCTOR_ROLE_TEXT = "doctor";
export const PHARMACY_ROLE_TEXT = "pharmacy";
export const LABO_ROLE_TEXT = "labo";
export const HOSPITAL_ROLE_TEXT = "hospital";

export const config = {
  num: [4, 7],
  rps: 0.01,
  radius: [5, 40],
  life: [1, 3],
  v: [1, 1],
  tha: [-40, 40],
  color: ["value", "#FFFFFF"],
};

export const DEFAULT_LOADING_LIMIT = 5;

export const isDoctor = (roleId) => roleId === 1;
export const isPharmacy = (roleId) => roleId === 1;
export const isLabo = (roleId) => roleId === 1;
export const isAssistant = (roleId) => roleId === ASSISTANT_ROLE;
export const isHospital = (roleId) => roleId === 1;

export const hasPermission = (permissions, permission) => {
  const listP = permissions?.reduce(
    (a, v) => ({
      ...a,
      [v?.id]: { id: v?.id },
    }),
    {}
  );
  return isNotNull(listP[permission]);
};

// DOCTOR PERMISSIONS
export const DOCTOR_PERMISSION_KPI = 1;
export const DOCTOR_PERMISSION_QUEUE_PATIENTS = 2;
export const DOCTOR_PERMISSION_CONSULTATION_BY_MONTH = 3;
export const DOCTOR_PERMISSION_MANAGE_ASSISTANTS = 4;
export const DOCTOR_PERMISSION_MANAGE_ASSISTANTS_ADD = 5;
export const DOCTOR_PERMISSION_MANAGE_ASSISTANTS_DROP = 6;
export const DOCTOR_PERMISSION_MANAGE_ASSISTANTS_UPDATE = 7;
export const DOCTOR_PERMISSION_MANAGE_APPOINTMENTS = 8;
export const DOCTOR_PERMISSION_MANAGE_APPOINTMENTS_ADD = 9;
export const DOCTOR_PERMISSION_MANAGE_APPOINTMENTS_UPDATE = 10;
export const DOCTOR_PERMISSION_MANAGE_ORDONANCE_ADD = 11;
export const DOCTOR_PERMISSION_MANAGE_ORDONANCE_DOWNLOAD = 12;
export const DOCTOR_PERMISSION_MANAGE_BILAN = 13;
export const DOCTOR_PERMISSION_MANAGE_BILAN_REQUEST = 14;
export const DOCTOR_PERMISSION_MANAGE_BILAN_STATUS = 15;
export const DOCTOR_PERMISSION_MANAGE_OBSERVATION = 16;
export const DOCTOR_PERMISSION_MANAGE_OBSERVATION_ADD = 17;
export const DOCTOR_PERMISSION_MANAGE_OBSERVATION_UPDATE = 18;
export const DOCTOR_PERMISSION_MANAGE_PATIENTS = 19;
export const DOCTOR_PERMISSION_MANAGE_PATIENTS_ADD = 20;
export const DOCTOR_PERMISSION_MANAGE_PATIENTS_HISTORIQUE = 21;
export const DOCTOR_PERMISSION_PHARMACY_MAP = 22;
export const DOCTOR_PERMISSION_LABORATOIRE_MAP = 23;
export const DOCTOR_PERMISSION_LOCAL_BILAN = 24;
export const DOCTOR_PERMISSION_MANAGE_SERVICES = 26;
export const DOCTOR_PERMISSION_MANAGE_SERVICES_ADD = 27;
export const DOCTOR_PERMISSION_MANAGE_SERVICES_DROP = 30;
export const DOCTOR_PERMISSION_MANAGE_SERVICES_UPDATE = 28;
export const DOCTOR_PERMISSION_MANAGE_SERVICES_TOGGLE = 29;

// PHARMACY PERMISSIONS
export const PHARMACY_PERMISSION_KPI = 1;
export const PHARMACY_PERMISSION_REVENUE_BY_MONTH = 2;
export const PHARMACY_PERMISSION_COMMANDS_BY_MONTH = 3;
export const PHARMACY_PERMISSION_MEDICAMENTS = 4;
export const PHARMACY_PERMISSION_VENDORS = 5;
export const PHARMACY_PERMISSION_VENDORS_ADD = 6;
export const PHARMACY_PERMISSION_VENDORS_UPDATE = 7;
export const PHARMACY_PERMISSION_VENDORS_DROP = 8;
export const PHARMACY_PERMISSION_STOCK = 9;
export const PHARMACY_PERMISSION_STOCK_ADD = 10;
export const PHARMACY_PERMISSION_STOCK_UPDATE = 11;
export const PHARMACY_PERMISSION_STOCK_HISTORY = 12;
export const PHARMACY_PERMISSION_STOCK_HISTORY_DOWNLOAD = 13;
export const PHARMACY_PERMISSION_COMMANDS = 14;
export const PHARMACY_PERMISSION_COMMANDS_ADD = 15;
export const PHARMACY_PERMISSION_COMMANDS_ACCEPT_REQUEST = 16;
export const PHARMACY_PERMISSION_COMMANDS_SHOW_MORE = 17;
export const PHARMACY_PERMISSION_ASSISTANCE = 18;
export const PHARMACY_PERMISSION_ASSISTANCE_ADD = 19;
export const PHARMACY_PERMISSION_ASSISTANCE_UPDATE = 20;
export const PHARMACY_PERMISSION_ASSISTANCE_DROP = 21;

//Labo Permissions
export const LABO_PERMISSION_ASSISTANCE = 15;
export const LABO_PERMISSION_ASSISTANCE_ADD = 16;
export const LABO_PERMISSION_ASSISTANCE_UPDATE = 17;
export const LABO_PERMISSION_ASSISTANCE_DROP = 18;
export const LABO_PERMISSION_BILAN = 4;
export const LABO_PERMISSION_BILAN_ADD = 5;
export const LABO_PERMISSION_BILAN_DROP = 7;
export const LABO_PERMISSION_BILAN_UPDATE = 6;
export const LABO_PERMISSION_COMMANDS_BY_MONTH = 3;
export const LABO_PERMISSION_DEMANDES = 10;
export const LABO_PERMISSION_DEMANDES_RESULTS = 12;
export const LABO_PERMISSION_DEMANDES_ADD = 11;
export const LABO_PERMISSION_DEMANDES_DOWNLOAD = 14;
export const LABO_PERMISSION_DEMANDES_UPDATE_RESULTS = 13;
export const LABO_PERMISSION_KPIS = 1;
export const LABO_PERMISSION_PATIENTS_ADD = 9;
export const LABO_PERMISSION_PATIENTS = 8;
export const LABO_PERMISSION_REVENU_BY_MONTH = 2;

// HOSPITAL PERMISSIONS
export const HOSPITAL_PERMISSION_KPI = 1;
export const HOSPITAL_PERMISSION_MANAGE_PATIENTS = 2;
export const HOSPITAL_PERMISSION_MANAGE_PATIENTS_ADD = 3;
export const HOSPITAL_PERMISSION_MANAGE_PATIENTS_DROP = 4;
export const HOSPITAL_PERMISSION_MANAGE_PATIENTS_UPDATE = 5;
export const HOSPITAL_PERMISSION_MANAGE_STAFF = 6;
export const HOSPITAL_PERMISSION_MANAGE_STAFF_ADD = 7;
export const HOSPITAL_PERMISSION_MANAGE_STAFF_DROP = 8;
export const HOSPITAL_PERMISSION_MANAGE_STAFF_UPDATE = 9;
export const HOSPITAL_PERMISSION_MANAGE_EQUIPMENT = 10;
export const HOSPITAL_PERMISSION_MANAGE_EQUIPMENT_ADD = 11;
export const HOSPITAL_PERMISSION_MANAGE_EQUIPMENT_DROP = 12;
export const HOSPITAL_PERMISSION_MANAGE_EQUIPMENT_UPDATE = 13;
export const HOSPITAL_PERMISSION_VIEW_REPORTS = 14;
export const HOSPITAL_PERMISSION_DOWNLOAD_REPORTS = 15;
export const HOSPITAL_PERMISSION_REQUEST_BILAN = 16;
export const HOSPITAL_PERMISSION_UPDATE_BILAN_STATUS = 17;
export const HOSPITAL_PERMISSION_MANAGE_OBSERVATION = 18;
export const HOSPITAL_PERMISSION_MANAGE_OBSERVATION_ADD = 19;
export const HOSPITAL_PERMISSION_MANAGE_OBSERVATION_UPDATE = 20;
export const HOSPITAL_PERMISSION_MANAGE_SOMETHING = 21;
export const HOSPITAL_PERMISSION_MANAGE_SOMETHING_ADD = 22;
export const HOSPITAL_PERMISSION_MANAGE_SOMETHING_UPDATE = 23;
export const HOSPITAL_PERMISSION_MAP_PHARMACIES = 24;
export const HOSPITAL_PERMISSION_MAP_LABORATORIES = 25;
export const HOSPITAL_PERMISSION_LOCAL_REPORTS = 26;
