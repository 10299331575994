import moment from "moment";

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function formatDate(date, format = 'DD/MM/YYYY') {
    return moment(date).format(format);
}

export function formatTime(date) {
    return moment(date).format('HH:mm');
}

export function formatFullTime(date) {
    return formatDate(date)+' '+formatTime(date);
}


export function isNull(obj) {
    return obj === undefined || obj === null;
}

export function isEmpty(ele) {
    if (typeof (ele) === "object") return Object.keys(ele).length === 0;
    return ele.length;
}

export function isNotNull(obj) {
    return obj !== undefined && obj !== null && obj.length !== 0;
}

export const debounce = (func, timeout = 500) => {
    let timer;
    return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, timeout)
    }
}
